<template>
  <section class="help-heading">
    <router-link to="/admin" class="breadcrumb">
      <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Admin
    </router-link>
    <h1 class="padding__md text__center">Helpful Tips</h1>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
</style>