<template>
  <li class="help-list-item margin__sm">
    <div class="help-list-item--question flex__between pointer" @click="toggleAnswer">
      <p class="text__bold text__powder">{{ question }}</p>
      <font-awesome-icon v-if="answerToggled" icon="caret-up" size="lg" />
      <font-awesome-icon v-else icon="caret-down" size="lg" />
    </div>
    <transition name="answer-open">
      <div v-if="answerToggled" class="help-list-item--answer">
        <simplebar class="answer--overflow" data-simplebar-auto-hide="false">
          <p class="pre-wrap">{{ answer }}</p>
        </simplebar>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  props: ["question", "answer"],
  data() {
    return {
      answerToggled: false
    };
  },
  methods: {
    toggleAnswer() {
      this.answerToggled = !this.answerToggled;
    }
  }
};
</script>