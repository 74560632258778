<template>
  <section class="help container">
    <Header />
    <section class="padding__sm max-width">
      <p
        class="mb-15"
      >Search for tips on using the website, helpful hints, and definitions of terms that we use across the site</p>
      <div class="search--bar">
        <input type="text" v-model="search" placeholder="search for tips" />
        <div class="btn__search">
          <font-awesome-icon icon="search" size="1x" />
        </div>
      </div>
    </section>
    <section class="help-section" v-for="group in filteredHelp" :key="group.name">
      <div class="header__sticky">
        <div class="tile-heading">
          <h2>{{ group.name }}</h2>
          <hr />
        </div>
      </div>
      <ul class="help-list">
        <HelpItem
          v-for="(item, index) in group.items"
          :key="`${group.name}-${index}`"
          :question="item.q"
          :answer="item.a"
        />
      </ul>
    </section>
  </section>
</template>

<script>
import Header from "./Header";
import HelpItem from "./HelpItem";
export default {
  components: {
    Header,
    HelpItem
  },
  metaInfo: {
    title: "Admin Help | WOW"
  },
  data() {
    return {
      search: "",
      help: [
        {
          name: "Users",
          items: [
            {
              q: "How to create a user",
              a:
                "From the admin page, click on Users at the top and then click on New User at the top right. Alternatively, you can scroll to the Processes section of the Admin page and click New User."
            },
            {
              q: "How to edit a user",
              a:
                "From the admin page, click on Users then select the desired user. At the top of all editable sections you can click the Edit button to begin making changes to that user. Be advised that all saved changes take effect immediately."
            },
            {
              q: "How to delete a user",
              a:
                "From the admin page, click on Users then select the desired user. At the top right click on Delete. Confirm you want to delete this user."
            },
            {
              q: "A user can't access their account",
              a:
                "From the admin page, click on Users then select the desired user. Check the Admin tile to see if they are 'active'. If they are, confirm that their current email matches the email on their user page. If it does, then contact the developer and or IT for assistance."
            },
            {
              q: "Finding the Happy Fox Id",
              a:
                "This ID is required for a user to be able to respond to tickets via this website. From Happy Fox, select the menu then select Agents under Manage. Right click the desired user then click inspect. In the developer window look up from the highlighted line to find something like 'data-test-id=table-row-id-5'. You want the number at the end."
            },
            {
              q: "User Permissions",
              a: `Non-users (those without accounts) may access the site, view products, and create an account if they are a member of the Flat Branch organization.\nA "User" may use the basic functions of this site including ordering, editing their own account, seeing their past orders, and contacting the WOW Desk.\nAn "Admin" has all of the user permissions with additional access to the Admin page. They will be able to manage orders and view this page.\nA "Super-Admin" has access to the entire site and all of its functions. They are able to create new users, add inventory, and manage products.\nA "Manager" has access to user abilities and limited access to the admin portion of the site to view reporting figures and access inventory figures.\nAn "ASM" has access to user abilities plus the ability to bill to any user of this site including to regional accounts.`
            }
          ]
        },
        {
          name: "Orders",
          items: [
            {
              q: "How to edit an order",
              a:
                "Click on one of the past orders. From the Confirm page you can alter the status of the order or respond to the ticket created by the order. You may click on a product to edit the customizations, alter the status, add tracking, or upload a receipt."
            },
            {
              q: "Complete an order",
              a:
                "To complete an order all order items must be moved to a status of 'completed'. From the order confirm screen you can set the status to 'completed'."
            },
            {
              q: "Responding to an order ticket",
              a:
                "Each order will have a ticket created in Happy Fox. You may reply to this ticket in Happy Fox. You may also respond to the ticket via the order confirm screen by clicking on the Ticketing button, typing in your response, and sending it."
            },
            {
              q: "Editing an order item",
              a:
                "From the order confirm screen of a past order you can click on a product to go to that order item. Change customizations at the bottom, upload a receipt, add a tracking number, or add notes to the order item if necessary."
            },
            {
              q: "Add a product to an existing order",
              a:
                "You can add a product to a past order if it is not completed. From the order confirm page click on 'back to products' at the top right. Select a product, fill out the customizations and click 'Add to Order'."
            },
            {
              q: "Delete an order",
              a:
                "You may delete an order at any time. Be advised, this cannot be undone. From the order confirm screen click on 'Delete Order' from the bottom of the page. Confirm that you want to delete."
            },
            {
              q: "Updating Award Amount Used",
              a:
                "For a past order, you can add back the award amount used to the user's account by marketing 'Using Award Balance' as No. When adding, updating, or deleting order items the used award balance will not change. To have the award amount used updated, mark Using Award Balance as No then remark it as Yes. This will add back the balance used, then remove as much balance as possible and apply it toward this order."
            }
          ]
        },
        {
          name: "Products",
          items: [
            {
              q: "How to create a product",
              a:
                "From the Admin page, click on Products at the top then click on New Product from the top right. Or from the Admin page scroll down and select the New Product image from the Processes tile. Fill out the information for the product. Once complete you will be able to fill out further information about this product and make it live."
            },
            {
              q: "How to update a product",
              a:
                "From the Admin page, click on Products at the top. Select the desired product. Then you may click the Edit button on any tile to make changes to the information present there."
            },
            {
              q: "How to delete a product",
              a:
                "You may make a product inactive by setting its status to 'test'. To remove a product from ever being used for further orders, from the Admin page, click on Products at the top. Select the desired product. Then click on Delete at the top right. Confirm you want to delete."
            },
            {
              q: "What are the product statuses",
              a:
                "A 'test' product will not appear for any users other than admin users. 'Coming soon' products will appear under products but cannot be ordered. 'Live' products can be ordered and viewed by all."
            },
            {
              q: "How to add customization",
              a:
                "Each product can have any amount of customization options. From the Admin page click on Products at the top. Select the desired product. Down the page there is a customization section. Edit existing ones or add a new one. Select the type of customization you want and fill in options to control how it works."
            },
            {
              q: "How to work with product tags",
              a:
                "Tags can be anything that you want them to be. They are not pre-defined options. Just type in a list of words or phrases separated by commas. For example, 'one, two, three, employee gift'. You can create very specific groupings of products by using tags. "
            }
          ]
        },
        {
          name: "Reporting",
          items: [
            {
              q: "How to filter",
              a:
                "From the Admin page, click on Reporting at the top or scroll down the page and click on the link in the lower right of the Reporting tile. At the top of the page you will see the filtering options. Fill in your desired filters and click Submit."
            },
            {
              q: "How to export results",
              a:
                "From the Admin page, click on Reporting at the top or scroll down the page and click on the link in the lower right of the Reporting tile. Fill out any filters you want to place on the data. By clicking on Export you will export the filtered data to excel."
            }
          ]
        },
        {
          name: "Product Images",
          items: [
            {
              q: "What resolution should they be",
              a:
                "Please use the highest resolution available so that the images look good across the entire site. This allows them to be big or small and still be sharp."
            },
            {
              q: "What dimensions should they be",
              a:
                "Square. The specific dimensions are not important. All of the images will be sized by the code in the site. What is important is making the height and width the same so the image is square."
            },
            {
              q: "How many for each product",
              a:
                "In order to provide a consistent and enjoyable experience, please provide at least 2 images for each product. Use different customizations of the same product, take the picture from different angles, with a model, etc."
            },
            {
              q: "How to set the image that shows first",
              a:
                "The first image that is in the array will always appear on the product tile. To change which is first, visit the admin products page and select a product. On the images section, click edit then drag and drop the images to reorder them. Order is set from left to right, top to bottom."
            }
          ]
        },
        {
          name: "Favorites",
          items: [
            {
              q: "How to create a favorite",
              a:
                "From the favorites page, click on New Favorite. Fill out the form to add products to this order. Then fill out the final details. All favorites are created with default information as the order details. This will be changed when the favorite is used as an order."
            },
            {
              q: "Edit a favorite",
              a:
                "From the favorites page, click on a favorite to edit it. Make changes to the information in the form and click Save at the bottom to save the changes."
            },
            {
              q: "Delete a favorite",
              a:
                "From the favorites page, click the Delete button on the desired favorite then confirm that you want to delete. Or you may click Edit then click Delete at the top right. Confirm your choice to delete."
            },
            {
              q: "Reorder favorites",
              a:
                "The first four favorites appear on a user's home page. To reorder favorites click on Reorder Favorites. Then click on a favorite to move it up / left to move it up in the order or down / right to move it down in the order."
            }
          ]
        },
        {
          name: "Other",
          items: [
            {
              q: "How to change product notes",
              a:
                "From the Admin page, click on Information at the top. Find the Notifications section. Click Edit then change the content next to the orange tile. This must have some content."
            },
            {
              q: "How to change the warning message",
              a:
                "From the Admin page, click on Information at the top. Find the Notifications section. Click edit then change the content next to the red tile. To remove the message entirely, clear out all content from this textbox and click Done."
            },
            {
              q: "How to work with categories",
              a:
                "From the Admin page, click on Information at the top. Find the Categories section. Type in the 'new category' textbox and click Create to create a new one. You may search for existing ones below that. To edit one, click Edit on the desired category, edit the text and click Done. To delete one, click Delete, then click Confirm to delete."
            }
          ]
        }
      ]
    };
  },
  computed: {
    filteredHelp() {
      if (!this.search) {
        return this.help;
      }
      return this.help
        .map(section => {
          const info = section.items
            .map(item => {
              if (
                item.q.toLowerCase().includes(this.search.toLowerCase()) ||
                item.a.toLowerCase().includes(this.search.toLowerCase())
              ) {
                return item;
              }
              return null;
            })
            .filter(item => item);
          if (info.length > 0) {
            return {
              name: section.name,
              items: info
            };
          }
          return null;
        })
        .filter(sect => sect);
    }
  }
};
</script>

<style scoped lang="scss">
.header__sticky {
  background-color: lighten($sky, 20%);
}
.max-width {
  max-width: 650px;
  margin: auto;
}
</style>